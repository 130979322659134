button.slick-arrow.slick-prev,
button.slick-arrow.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  // width: 20px;
  height: 100%;
  padding: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  color: transparent;
  outline: none;
  background: transparent;
  width: 5%;
  z-index: 2;
  opacity: 0.5;
}
.slick-prev {
  left: 0;
  &::before {
    content: "\f053";
    font-weight: 600;
    font-family: "Font Awesome\ 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: black;
    font-size: 40px;
    width: 100%;
    text-align: left;
  }
}
.slick-next {
  right: 0;
  &::before {
    content: "\f054";
    font-weight: 600;
    font-family: "Font Awesome\ 5 Free";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    color: black;
    font-size: 40px;
    width: 100%;
    text-align: right;
  }
}

body {
  background-color: white;
}

.rounded-container {
  border-radius: 10px;
  &::after {
    border-radius: 10px;
  }
} 

.image-gallery-icon {
  margin-left: 20px;
  margin-right: 20px;
  &:hover {
    color: #1ad1a2;
  }
  &::before {
    content: "";
  }
}
.image-gallery-swipe {
  min-height: 300px;
}

.image-gallery-fullscreen-button {
  top: 0!important;
  bottom: initial;
  &:hover {
    color: #1ad1a2;
  }
  .image-gallery-svg {
    height: 22px;
  }
  
}